import React from "react";

const HeartIcon = ({ className }) => {
  return (
    <svg
      width="31px"
      height="31px"
      viewBox="0 0 31 31"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="heart-2-copy-3">
        <path
          d="M15.36 30.72C23.8431 30.72 30.72 23.8431 30.72 15.36C30.72 6.87691 23.8431 0 15.36 0C6.87691 0 0 6.87691 0 15.36C0 23.8431 6.87691 30.72 15.36 30.72Z"
          id="Oval"
          fill="#FFFFFF"
          stroke="none"
        />
        <path
          d="M14.4 0C12.4243 0 10.909 1.11066 9.9 2.76348C8.8908 1.11036 7.37538 0 5.4 0C3.86934 0 2.47272 0.64608 1.46754 1.8192C0.52122 2.92374 0 4.39878 0 5.9727C0 7.68408 0.64188 9.26622 2.01996 10.9519C3.22824 12.4298 4.96284 13.9501 6.9714 15.7106C7.71396 16.3615 8.48184 17.0345 9.2979 17.769C9.46908 17.923 9.68454 18 9.9 18C10.1155 18 10.3309 17.923 10.5021 17.7689C11.3182 17.0345 12.086 16.3615 12.8286 15.7106C14.8372 13.9501 16.5718 12.4297 17.78 10.9518C19.1581 9.26628 19.8 7.68408 19.8 5.9727C19.8 2.75448 17.5951 8.52651e-16 14.4 0Z"
          transform="translate(5.4599996 7.2599998)"
          id="Path"
          fill="currentColor"
          fillOpacity="0.6050451"
          stroke="none"
        />
      </g>
    </svg>
  );
};

export default HeartIcon;
